import { simpleFormHandler } from '@frontend/jetlend-core/src/ducks/form';
import {
    isEmptyValue,
    requireTrue,
} from '@frontend/jetlend-core/src/validators';
import {
    put,
    select,
} from 'redux-saga/effects';
import { ISmsSigned } from '@frontend/jetlend-core/src/models/sms';
import { IUserCreateFormValues } from '@app/models/common/login';
import { apiPostUserCreate } from '@app/services/client/common/loginService';
import {
    commonLoginStateHandler,
    setRedirectUriFormValues,
} from './login';
import {
    CLIENT_TYPE_CABINET_URLS,
    CommonStage,
} from '@app/models/common/common';
import { accountInfoHandler } from './account';
import { IAccountInfoApiModel } from '@app/models/common/account';
import { signDataWithSmsSaga } from './sms';

export const VERSION = 2;
export const PREFIX = 'common/create';

/**
 * Хендлер формы для создания нового мульти-аккаунта пользователя.
 */
export const commonUserCreateFormHandler = simpleFormHandler<IUserCreateFormValues>(
    PREFIX, 'user_create_form', {
        v2: {
            confirm: [ requireTrue() ],
        },
    }, {
        apiMethod: apiPostUserCreate,
        *onBeforeSubmit (values: IUserCreateFormValues) {
            yield put(commonLoginStateHandler.update({
                stage: CommonStage.Sms,
            }));

            const account: IAccountInfoApiModel = yield select(accountInfoHandler.selector);
            // Если не авторизованы, то ничего делать не требуется
            if (!account) {
                return;
            }

            const { phone }: IAccountInfoApiModel = account;

            const smsId = yield signDataWithSmsSaga(values.clientType, phone, values, 'user-create');

            if (!smsId) {
                yield put(commonLoginStateHandler.update({
                    stage: CommonStage.Login,
                }));

                return;
            }

            // Show spinner on Registration modal
            yield put(commonLoginStateHandler.update({
                stage: CommonStage.Loading,
                loadingTitle: 'Создание аккаунта',
            }));

            const params = new URL(document.location.href).searchParams;
            const enable_market_making = Boolean(params.get('enable_market_making'));
            const enable_transfer = Boolean(params.get('enable_transfer'));
            const type = params.get('type');

            const payload = {
                ...values,
                sms_id: smsId,
                type: type || undefined,
            } satisfies IUserCreateFormValues & ISmsSigned;

            // TODO Нужно будет удалить в 2025 году
            // Остается для обратной совмести со старыми версиями приложения
            if (enable_market_making) {
                payload.enable_market_making = enable_market_making;
            }

            // TODO Нужно будет удалить в 2025 году
            // Остается для обратной совмести со старыми версиями приложения
            if (enable_transfer) {
                payload.enable_transfer = enable_transfer;
            }

            return setRedirectUriFormValues(payload);
        },
        onSuccess (response, values: IUserCreateFormValues) {
            const { clientType } = values;

            const redirectUri = response.data?.redirect_uri ?? CLIENT_TYPE_CABINET_URLS[clientType];

            if (!isEmptyValue(redirectUri)) {
                document.location.href = redirectUri;
            }
        },
        *onFormError () {
            yield put(commonLoginStateHandler.update({
                stage: CommonStage.Create,
            }));
        },
        echo: true,
    }, {}
);
